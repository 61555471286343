<template>
    <div class="mb-6 relative z-10">
        <div class="text-black text-2xl font-semibold flex items-center">
            <span class="text-indigo-600 mr-2 p-1.5 bg-white/70 backdrop-blur-sm rounded-lg shadow-sm border border-white/50 flex items-center justify-center">
                <NuxtImg src="/android-chrome-192x192.png" class="h-8 w-8" />
            </span>
            <span class="font-bold">Nomad<span class="text-indigo-600">Rx</span></span>
        </div>
    </div>
</template>
<script setup lang="ts">
</script>