<template>
    <footer class="w-full bg-white border-t border-gray-100 py-8 px-12 md:px-20">
        <div class="max-w-7xl mx-auto">
            <!-- Main footer content -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <!-- Company info and logo -->
                <div class="col-span-1 md:col-span-1">
                    <NomadRxLogoComponent class="h-8 mb-4" />
                    <p class="text-sm text-gray-600 leading-relaxed mb-4 font-merriweather">
                        Enabling convenience, quality, and access for healthcare providers and patients - wherever you are.
                    </p>
                    <div class="flex space-x-4">
                        <a href="#" class="text-gray-500 hover:text-indigo-600 transition-colors">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
                            </svg>
                        </a>
                        <a href="https://wa.me/15125246062" target="_blank" rel="noopener noreferrer" class="text-gray-500 hover:text-indigo-600 transition-colors">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                            </svg>
                        </a>
                    </div>
                </div>

                <!-- Solutions -->
                <div class="col-span-1">
                    <h3 class="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">Solutions</h3>
                    <ul class="space-y-3">
                        <li><a href="/for-patients" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Borderless insurance acceptance for patients</a></li>
                        <li><a href="/for-providers" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Insurance acceptance solutions for medical providers</a></li>
                        <li><a href="/" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Nomad Scribe for medical providers</a></li>
                        <!--                        <li><a href="#" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Patient Care</a></li>-->
                    </ul>
                </div>

                <!-- Company -->
<!--                <div class="col-span-1">-->
<!--                    <h3 class="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">Company</h3>-->
<!--                    <ul class="space-y-3">-->
<!--                        <li><a href="#" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">About Us</a></li>-->
<!--                        <li><a href="#" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Careers</a></li>-->
<!--                        <li><a href="#" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Blog</a></li>-->
<!--                        <li><a href="#" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Contact</a></li>-->
<!--                    </ul>-->
<!--                </div>-->

                <!-- Legal -->
<!--                <div class="col-span-1">-->
<!--                    <h3 class="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">Legal</h3>-->
<!--                    <ul class="space-y-3">-->
<!--                        <li><a href="#" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Privacy Policy</a></li>-->
<!--                        <li><a href="#" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Terms of Service</a></li>-->
<!--                        <li><a href="#" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">HIPAA Compliance</a></li>-->
<!--                        <li><a href="#" class="text-gray-600 hover:text-indigo-600 text-sm transition-colors">Security</a></li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>

            <!-- Bottom footer with copyright -->
            <div class="mt-12 pt-8 border-t border-gray-100">
                <div class="flex flex-col md:flex-row justify-between items-center">
                    <p class="text-sm text-gray-500">
                        &copy; {{ currentYear }} NomadRx, Inc. All rights reserved.
                    </p>
<!--                    <div class="mt-4 md:mt-0">-->
<!--                        <div class="inline-flex items-center px-4 py-1.5 bg-white/80 backdrop-blur-sm border border-gray-200/50 shadow-sm text-indigo-700 rounded-full text-xs font-medium tracking-wide">-->
<!--                            HIPAA & GDPR Compliant-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import NewLogoComponent from '~/components/landing/NewLogoComponent.vue'
import NomadRxLogoComponent from "~/components/landing/NomadRxLogoComponent.vue";

const currentYear = ref(new Date().getFullYear())
</script>